import { useState } from 'react';

import { DataProxy, useMutation } from '@apollo/client';

import { useNewDashboardContext } from '@crehana/web';

import COURSE_ENROLLMENTS_QUERY from '../graphql/GetUserEnrollmentsQuery.v5.graphql';
import TOGGLE_HIDDEN_COURSE_MUTATION from '../graphql/ToggleHiddenUserDashboardCourse.graphql';
import {
  GetUserEnrollmentsQuery_content_enrollments_user_enrollments_edges as EnrollmentNodeEdge,
  GetUserEnrollmentsQuery,
  GetUserEnrollmentsQueryVariables,
} from '../graphql/types/GetUserEnrollmentsQuery';
import type {
  ToggleHiddenUserDashboardCourse as ToggleHiddenCourseResponse,
  ToggleHiddenUserDashboardCourseVariables as ToggleHiddenCourseVariables,
} from '../graphql/types/ToggleHiddenUserDashboardCourse';

const useToggleHiddenCourse = ({
  currentQueryVars,
}: {
  currentQueryVars?: GetUserEnrollmentsQueryVariables;
}) => {
  const [toggleHiddenCourseMutation] = useMutation<
    ToggleHiddenCourseResponse,
    ToggleHiddenCourseVariables
  >(TOGGLE_HIDDEN_COURSE_MUTATION);
  const { openErrorSnackbar } = useNewDashboardContext();

  const [enrollmentsLoadingHiddenState, setEnrollmentsLoadingHiddenState] =
    useState<number[]>([]);

  const removeEnrollmentFromQuery = ({
    cache,
    cacheData,
    variables,
    enrollmentId,
  }: {
    cache: DataProxy;
    cacheData: GetUserEnrollmentsQuery;
    variables: GetUserEnrollmentsQueryVariables;
    enrollmentId: number | null;
  }) => {
    if (cacheData.content_enrollments.user_enrollments) {
      cache.writeQuery<
        GetUserEnrollmentsQuery,
        GetUserEnrollmentsQueryVariables
      >({
        query: COURSE_ENROLLMENTS_QUERY,
        variables,
        data: {
          ...cacheData,
          content_enrollments: {
            ...cacheData.content_enrollments,
            user_enrollments: {
              ...cacheData.content_enrollments.user_enrollments,
              edges:
                cacheData.content_enrollments.user_enrollments.edges.filter(
                  enrollment => enrollment?.node?.id !== `${enrollmentId}`,
                ),
            },
          },
        },
      });
    }
  };

  const addEnrollmentToQuery = ({
    cache,
    cacheData,
    variables,
    enrollmentId,
    isHidden,
  }: {
    cache: DataProxy;
    cacheData: GetUserEnrollmentsQuery;
    variables: GetUserEnrollmentsQueryVariables;
    enrollmentId: number | null;
    isHidden: boolean;
  }) => {
    if (cacheData.content_enrollments.user_enrollments) {
      const enrollmentNodeEdge: EnrollmentNodeEdge | null | undefined =
        cacheData.content_enrollments.user_enrollments.edges.find(
          item => item?.node?.id === `${enrollmentId}`,
        );

      if (enrollmentNodeEdge) {
        cache.writeQuery<
          GetUserEnrollmentsQuery,
          GetUserEnrollmentsQueryVariables
        >({
          query: COURSE_ENROLLMENTS_QUERY,
          variables,
          data: {
            ...cacheData,
            content_enrollments: {
              ...cacheData.content_enrollments,
              user_enrollments: {
                ...cacheData.content_enrollments.user_enrollments,
                edges: [
                  {
                    ...enrollmentNodeEdge,
                    node: {
                      ...enrollmentNodeEdge.node!,
                      is_hidden: isHidden,
                    },
                  },
                  ...cacheData.content_enrollments.user_enrollments.edges,
                ],
              },
            },
          },
        });
      }
    }
  };

  const toggleHiddenCourse = ({ enrollmentId }: { enrollmentId: number }) => {
    setEnrollmentsLoadingHiddenState(prevState => [...prevState, enrollmentId]);
    toggleHiddenCourseMutation({
      variables: { enrollmentId },
      update: (cache, res) => {
        const success = res.data?.toggleEnrollment?.success;
        const isHidden = res.data?.toggleEnrollment?.isHidden;

        console.log('currentQueryVars', currentQueryVars);

        if (success) {
          let allCoursesCacheData: GetUserEnrollmentsQuery | null;
          let filteredCoursesCacheData: GetUserEnrollmentsQuery | null;
          let hiddenCoursesCacheData: GetUserEnrollmentsQuery | null;

          try {
            const allCoursesVariables: GetUserEnrollmentsQueryVariables = {
              ...currentQueryVars,
              filter_by: {
                is_hidden: false,
              },
              order_by: null,
            };

            allCoursesCacheData = cache.readQuery<
              GetUserEnrollmentsQuery,
              GetUserEnrollmentsQueryVariables
            >({
              query: COURSE_ENROLLMENTS_QUERY,
              variables: allCoursesVariables,
            });

            if (allCoursesCacheData) {
              if (isHidden) {
                // Remove enrollment from the main courses query
                removeEnrollmentFromQuery({
                  cache,
                  cacheData: allCoursesCacheData,
                  variables: allCoursesVariables,
                  enrollmentId,
                });
              } else {
                // Add enrollment to the main courses query
                addEnrollmentToQuery({
                  cache,
                  cacheData: allCoursesCacheData,
                  variables: allCoursesVariables,
                  enrollmentId,
                  isHidden: false,
                });
              }
            }
          } catch (e) {
            // The main courses query hasn't been called yet
          }
          try {
            const filteredCoursesVariables: GetUserEnrollmentsQueryVariables = {
              ...currentQueryVars,
            };

            filteredCoursesCacheData = cache.readQuery<
              GetUserEnrollmentsQuery,
              GetUserEnrollmentsQueryVariables
            >({
              query: COURSE_ENROLLMENTS_QUERY,
              variables: filteredCoursesVariables,
            });

            if (filteredCoursesCacheData) {
              if (isHidden) {
                // Remove enrollment from the corresponding filtered query
                removeEnrollmentFromQuery({
                  cache,
                  cacheData: filteredCoursesCacheData,
                  variables: filteredCoursesVariables,
                  enrollmentId,
                });
              } else {
                // Add enrollment to the corresponding filtered query
                addEnrollmentToQuery({
                  cache,
                  cacheData: filteredCoursesCacheData,
                  variables: filteredCoursesVariables,
                  enrollmentId,
                  isHidden: false,
                });
              }
            }
          } catch (e) {
            // The corresponding filtered query hasn't been called yet
          }
          try {
            const hiddenCoursesVariables: GetUserEnrollmentsQueryVariables = {
              ...currentQueryVars,
              filter_by: {
                is_hidden: true,
              },
            };

            hiddenCoursesCacheData = cache.readQuery<
              GetUserEnrollmentsQuery,
              GetUserEnrollmentsQueryVariables
            >({
              query: COURSE_ENROLLMENTS_QUERY,
              variables: hiddenCoursesVariables,
            });

            if (hiddenCoursesCacheData) {
              if (isHidden) {
                // Add enrollment to the hidden courses query
                addEnrollmentToQuery({
                  cache,
                  cacheData: hiddenCoursesCacheData,
                  variables: hiddenCoursesVariables,
                  enrollmentId,
                  isHidden: true,
                });
              } else {
                // Remove enrollment from the hidden courses query
                removeEnrollmentFromQuery({
                  cache,
                  cacheData: hiddenCoursesCacheData,
                  variables: hiddenCoursesVariables,
                  enrollmentId,
                });
              }
            }
          } catch (e) {
            // The hidden courses query hasn't been called yet
          }
        } else {
          openErrorSnackbar();
        }
        setEnrollmentsLoadingHiddenState(prevState =>
          prevState.filter(id => id !== enrollmentId),
        );
      },
    }).catch(() => {
      openErrorSnackbar();
      setEnrollmentsLoadingHiddenState(prevState =>
        prevState.filter(id => id !== enrollmentId),
      );
    });
  };

  return { toggleHiddenCourse, enrollmentsLoadingHiddenState };
};

export default useToggleHiddenCourse;
