import { COURSE_RULES_TRANSLATION_KEY } from '../constants';

const localeEs = {
  [COURSE_RULES_TRANSLATION_KEY]: {
    // Filters
    UDE_SEARCH_PLACEHOLDER: 'Buscar cursos',
    UDE_FILTER_ALL_TITLE: 'Resultado',
    UDE_FILTER_ALL_LABEL: 'Todos',
    UDE_FILTER_ASSIGNED_LABEL: 'Cursos asignados',
    UDE_FILTER_INITIAL_LABEL: 'Por empezar',
    UDE_FILTER_PROGRESS_LABEL: 'En progreso',
    UDE_FILTER_COMPLETED_LABEL: 'Completados',
    UDE_FILTER_HIDDEN_LABEL: 'Ocultos',
    UDE_ORDER_BY: 'Ordenar por',
    UDE_FILTER_BY: 'Filtrar por',
    UDE_ORDER_BY_COURSE_TITLE: 'De la A - Z',
    UDE_ORDER_BY_ENROLLMENT_DESC: 'Más recientes',
    UDE_ORDER_BY_ENROLLMENT_ASC: 'Más antiguos',
    UDE_SEARCH_NO_RESULTS: 'No se encontraron resultados para',
    USE_SEARCH_RETRY: 'Inténtalo de nuevo con un término diferente.',
    // Empty view
    UDE_ALL_EMPTY_TITLE: 'Aún no tienes cursos inscritos',
    UDE_ALL_EMPTY_DESC: 'Explora nuestro catálogo y descubre tu primer curso.',
    UDE_GO_TO_CATALOG: 'Ir al catálogo',
    UDE_ASSIGNED_EMPTY_TITLE: 'Aún no tienes cursos asignados',
    UDE_ASSIGNED_EMPTY_DESC:
      'Comunícate con el administrador de tu organización para comenzar con tu aprendizaje.',
    UDE_INITIAL_EMPTY_TITLE: 'Vaya, vaya... No tienes cursos por empezar',
    UDE_INITIAL_EMPTY_DESC:
      '¡No te quedes atrás! Es momento de aprender nuevas habilidades con Crehana',
    UDE_PROGRESS_EMPTY_TITLE: 'Tus cursos esperan por ti',
    UDE_PROGRESS_EMPTY_DESC:
      '¿Todo bien? Vemos que aún no comienzas ninguno de tus cursos inscritos en Crehana. ¡Es momento de aprender!',
    UDE_PROGRESS_EMPTY_BUTTON: 'Ver cursos por empezar',
    UDE_COMPLETED_EMPTY_TITLE: '¡No te quedes atrás!',
    UDE_COMPLETED_EMPTY_DESC:
      'Vemos que aún no completas ningún curso en Crehana. ¡Vamos que falta poco!\nNo pierdas la oportunidad de seguir aprendiendo ¡Te esperamos en clases!',
    UDE_COMPLETED_EMPTY_BUTTON: 'Ver cursos en progreso',
    UDE_HIDDEN_EMPTY_TITLE: 'No tienes cursos ocultos',
    UDE_HIDDEN_EMPTY_DESC_1:
      'En el caso de que quieras ocultar alguno de tus cursos, selecciona el ícono',
    UDE_HIDDEN_EMPTY_DESC_2:
      'Una vez ocultado, lo podrás encontrar en esta sección.',
    UDE_HIDDEN_EMPTY_BUTTON: 'Ver todos mis cursos',
    // Error view
    UDE_COURSES_ERROR: 'Ocurrió un error al obtener tus cursos',
    UDE_RECOMMENDED_COURSES_ERROR:
      'Ocurrió un error al obtener cursos recomendados',
    // Course card
    UDE_COURSE_PROGRESS: '{{progress}}% Completado',
    UDE_COURSE_COMPLETED: 'Curso completado',
    UDE_HIDE_COURSE: 'Ocultar curso',
    UDE_UNHIDE_COURSE: 'Quitar de ocultos',
    UDE_FREE_COURSE: 'Curso gratis',
    UDE_FREE_ACCESS_COURSE: 'Acceso gratuito',
    UDE_DOWNLOAD_DIPLOMA: 'Descargar diploma',
    UDE_PROVIDER_NAME: 'Crehana',

    // Card Lead
    CARD_LEAD_TITLE: 'Aprende sin límites',
    CARD_LEAD_COPY:
      'Estás a una llamada de acceder al mejor descuento en 800 cursos, mentorías, especializaciones y más.',
    CARD_LEAD_CTA: 'Conversemos',

    // Card renewal
    CARD_RENEWAL_TITLE_WITH_NAME:
      '{{firstname}}, ¡felicitaciones por tus logros de este año!',
    CARD_RENEWAL_TITLE_WITHOUT_NAME:
      '¡Felicitaciones por tus logros de este año!',
    CARD_RENEWAL_MESSAGE:
      'Te quedan {{remainingDays}} días más para disfrutar de tu membresía {{membershipType}}. ¿Vamos por 1 año más? Renueva y sigue creciendo.',
    CARD_RENEWAL_CTA: 'Quiero renovar',
  },
};

export default localeEs;
