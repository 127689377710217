import { useQuery } from '@apollo/client/react';

import { TBlockingDependency } from '@crehana/compass.ui';
import { date } from '@crehana/utils';

import {
  UserCoursesDependenciesQuery,
  UserCoursesDependenciesQuery_user_course_dependencies,
  UserCoursesDependenciesQueryVariables,
} from '../graphql/types/UserCoursesDependenciesQuery';
import USER_COURSE_DEPENDENCIES_QUERY from '../graphql/UserCoursesDependenciesQuery.v5.graphql';

const parseDependencies = (
  blockingDependencies?: UserCoursesDependenciesQuery_user_course_dependencies[],
) => {
  if (!blockingDependencies || blockingDependencies?.length === 0) return [];

  return blockingDependencies.map(courseDependencies => {
    const dependenciesMap = new Map<
      number,
      TBlockingDependency['dependencies'][number]
    >();

    courseDependencies.depends_on.forEach(teamDependencies => {
      teamDependencies.courses.forEach(({ course }) => {
        dependenciesMap.set(parseInt(course.id, 10), {
          id: parseInt(course.id, 10),
          title: course.title,
          image: course.image ?? '',
          level: course.level?.name ?? '',
          duration: date.toMinutes(course.duration),
          professor: `${course.professor?.first_name ?? ''} ${
            course.professor?.last_name ?? ''
          }`.trim(),
        });
      });
    });

    return {
      isUnlocked: courseDependencies.is_unlocked,
      courseId: courseDependencies.course_id,
      dependencies: Array.from(dependenciesMap.values()),
    };
  });
};

export default function useBlockingDependencies({
  organizationId,
  courseIds,
}: {
  organizationId: number;
  courseIds: number[];
}) {
  const { data, loading, error } = useQuery<
    UserCoursesDependenciesQuery,
    UserCoursesDependenciesQueryVariables
  >(USER_COURSE_DEPENDENCIES_QUERY, {
    variables: {
      organizationId: organizationId.toString(),
      courseIds,
    },
    context: {
      clientName: 'v5',
    },
  });

  return {
    blockingDependencies: parseDependencies(data?.user_course_dependencies),
    loading,
    error,
  };
}
