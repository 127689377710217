import { useCallback } from 'react';

import { useQuery } from '@apollo/client';

import GET_USER_ENROLLMENTS_QUERY from '../../graphql/GetUserEnrollmentsQuery.v5.graphql';
import type {
  GetUserEnrollmentsQuery,
  GetUserEnrollmentsQueryVariables,
} from '../../graphql/types/GetUserEnrollmentsQuery';
import { DEFAULT_COURSE_ENROLLMENTS_QUERY_VARIABLES } from './constants';
import { parseCourseEnrollmentsQuery } from './utils';

const useUserEnrollments = (
  params?: Partial<GetUserEnrollmentsQueryVariables> & {
    skip?: boolean;
  },
) => {
  const { skip, ...variables } = params || {};

  const { data, refetch, ...courseEnrollmentsQuery } = useQuery<
    GetUserEnrollmentsQuery,
    GetUserEnrollmentsQueryVariables
  >(GET_USER_ENROLLMENTS_QUERY, {
    context: { clientName: 'v5' },
    variables: {
      ...DEFAULT_COURSE_ENROLLMENTS_QUERY_VARIABLES,
      ...variables,
    },
    skip,
    notifyOnNetworkStatusChange: true,
  });
  const totalCount =
    data?.content_enrollments?.user_enrollments?.total_count || 0;

  const onRefetch = useCallback(
    (refetchVariables?: GetUserEnrollmentsQueryVariables) =>
      refetch(refetchVariables),
    [refetch],
  );

  return {
    totalCount,
    courseEnrollments: parseCourseEnrollmentsQuery(data),
    onRefetch,
    refetch,
    ...courseEnrollmentsQuery,
  };
};

export default useUserEnrollments;
