import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
import { scroll } from '@crehana/utils';
/**
 * @deprecated This hook is deprecated. Please use https://www.apollographql.com/docs/react/pagination/core-api/#the-fetchmore-function
 */
var usePagination = function usePagination(itemsPerPage, _ref) {
  var refetch = _ref.refetch,
    totalElements = _ref.totalElements,
    onPageChanged = _ref.onPageChanged,
    scrollTopOnPageChange = _ref.scrollTopOnPageChange,
    _ref$ignoreWarning = _ref.ignoreWarning,
    ignoreWarning = _ref$ignoreWarning === void 0 ? false : _ref$ignoreWarning;
  var _useState = useState(1),
    _useState2 = _slicedToArray(_useState, 2),
    currentPage = _useState2[0],
    setCurrentPage = _useState2[1];
  var _useState3 = useState(),
    _useState4 = _slicedToArray(_useState3, 2),
    refetchVars = _useState4[0],
    setRefetchVars = _useState4[1];
  if (process.env.NODE_ENV === 'development' && !ignoreWarning) {
    console.warn('usePagination is deprecated. Please use https://www.apollographql.com/docs/react/pagination/core-api/#the-fetchmore-function');
  }
  var resetPagination = function resetPagination() {
    setCurrentPage(1);
  };

  // go back to page 1 if the total elements has changed
  // useEffect(() => {
  //   resetPagination();
  // }, [totalElements]);

  var totalPages = Math.ceil(totalElements / itemsPerPage);
  var onPageChange = function onPageChange(page) {
    var endCursor = page > 1 ? btoa("arrayconnection:".concat((page - 1) * itemsPerPage - 1)) : undefined;
    refetch({
      first: itemsPerPage,
      after: endCursor
    });
    setRefetchVars({
      first: itemsPerPage,
      after: endCursor
    });
    setCurrentPage(page);
    if (onPageChanged) {
      onPageChanged();
    }
    if (scrollTopOnPageChange) {
      scroll.scrollTop();
    }
  };
  return {
    onPageChange: onPageChange,
    currentPage: currentPage,
    totalPages: totalPages,
    resetPagination: resetPagination,
    refetchVars: refetchVars
  };
};
export default usePagination;