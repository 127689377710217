import { useTranslation } from '@crehana/i18n';

import { COURSE_RULES_TRANSLATION_KEY } from '../constants';
import { CoursesLocalesBundle } from '../i18n';
import type { TCoursesWithRulesLocaleKeys } from '../types';

const useCoursesWithRulesTranslation = () => {
  const { addResourceBundle, ...rest } =
    useTranslation<TCoursesWithRulesLocaleKeys>(COURSE_RULES_TRANSLATION_KEY);

  addResourceBundle(CoursesLocalesBundle);

  return rest;
};

export default useCoursesWithRulesTranslation;
