import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from "@crehana/i18n";
import { NEW_DASHBOARD_TEMPLATE_ID } from "../constants";
import { LOCALES } from "../i18n";
var useNewDashboardTranslations = function useNewDashboardTranslations() {
  var _useTranslation = useTranslation(NEW_DASHBOARD_TEMPLATE_ID),
    addResourceBundle = _useTranslation.addResourceBundle,
    translationContextValue = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(LOCALES);
  return translationContextValue;
};
export default useNewDashboardTranslations;